import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'protected_buy';
const namespace = 'ui-pdp-icon ui-pdp-icon--protected';

const IconProtected = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconProtected.propTypes = {
  className: string,
};

IconProtected.defaultProps = {
  className: null,
};

IconProtected.ICON_ID = ICON_ID;

export default React.memo(IconProtected);
export { IconProtected };
